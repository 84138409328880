import { createStore } from 'vuex'

export default createStore({
  state: {
    items: [
      {
        category   : 'Верхняя одежда',
        title      : 'Куртка градиент оверсайз',
        description: 'Куртка с дизайнерским принтом, фасон оверсайз. Светится под неоновым светом. Теплый наполнитель до -25\n',
        sizes      : ['xs/s', 's/m', 'm/l', 'm', 'l'],
        color      : 'Мультиколор',
        image      : 'images/catalog/1.jpg',
        slider     : [
          'images/gradient-jacket/1.jpg',
          'images/gradient-jacket/2.jpg',
          'images/gradient-jacket/3.jpg'
        ],
        check      : true,
        sell       : false,
        price      : 3200,
        discount   : 0
      },
    
      {
        category   : 'Костюмы',
        title      : 'Костюм в полоску фуксия',
        description: 'Костюм выполнен из пряжи премиум-класса, очень мягкий и теплый, состав пряжи - 70% шерсть мериноса и 30% акрил.\n',
        sizes      : ['xs/s', 's/m', 'm/l', 'm', 'l'],
        color      : 'Фиолетовый',
        image      : 'images/catalog/2.jpg',
        slider     : [
          'images/violet-costume/1.jpg',
          'images/violet-costume/2.jpg',
          'images/violet-costume/3.jpg'
        ],
        check      : true,
        sell       : false,
        price      : 2200,
        discount   : 0
      },
      {
        category   : 'Костюмы',
        title      : 'Костюм в полоску оливковый',
        description: 'Костюм выполнен из пряжи премиум-класса, очень мягкий и теплый, состав пряжи - 70% шерсть мериноса и 30% акрил.\n',
        sizes      : ['xs/s', 's/m', 'm/l', 'm', 'l'],
        color      : 'Зеленый',
        image      : 'images/catalog/3.jpg',
        slider     : [
          'images/olive-costume/1.jpg',
          'images/olive-costume/2.jpg',
          'images/olive-costume/3.jpg'
        ],
        check      : true,
        sell       : false,
        price      : 2200,
        discount   : 0
      },
    
      {
        category   : 'Костюмы',
        title      : 'Костюм бежевый',
        description: 'Костюм выполнен из пряжи премиум-класса, очень мягкий и теплый, состав пряжи - 70% шерсть мериноса и 30% акрил.\n',
        sizes      : ['xs/s', 's/m', 'm/l', 'm', 'l'],
        color      : 'Бежевый',
        image      : 'images/catalog/4.jpg',
        slider     : [
          'images/beige-costume/1.jpg',
          'images/beige-costume/2.jpg',
          'images/beige-costume/3.jpg'
        ],
        check      : true,
        sell       : true,
        price      : 1800,
        discount   : 0
      },
    
      {
        category   : 'Костюмы',
        title      : 'Костюм кремовый',
        description: 'Костюм выполнен из пряжи премиум-класса, очень мягкий и теплый, состав пряжи - 70% шерсть мериноса и 30% акрил.\n',
        sizes      : ['xs/s', 's/m', 'm/l', 'm', 'l'],
        color      : 'Белый',
        image      : 'images/catalog/5.jpg',
        slider     : [
          'images/cream-costume/1.jpg',
          'images/cream-costume/2.jpg',
          'images/cream-costume/3.jpg'
        ],
        check      : true,
        sell       : false,
        price      : 1800,
        discount   : 200
      },
    
      {
        category   : 'Костюмы',
        title      : 'Костюм черный',
        description: 'Костюм выполнен из пряжи премиум-класса, очень мягкий и теплый, состав пряжи - 70% шерсть мериноса и 30% акрил.\n',
        sizes      : ['xs/s', 's/m', 'm/l', 'm', 'l'],
        color      : 'Белый',
        image      : 'images/catalog/6.jpg',
        slider     : [
          'images/black-costume/1.jpg',
          'images/black-costume/2.jpg',
          'images/black-costume/3.jpg'
        ],
        check      : true,
        sell       : false,
        price      : 1800,
        discount   : 0
      },
    
      {
        category   : 'Костюмы',
        title      : 'Костюм фуксия',
        description: 'Костюм выполнен из пряжи премиум-класса, очень мягкий и теплый, состав пряжи - 70% шерсть мериноса и 30% акрил.\n',
        sizes      : ['xs/s', 's/m', 'm/l', 'm', 'l'],
        color      : 'Белый',
        image      : 'images/catalog/7.jpg',
        slider     : [
          'images/violet-costume2/1.jpg',
          'images/violet-costume2/2.jpg',
          'images/violet-costume2/3.jpg'
        ],
        check      : true,
        sell       : false,
        price      : 1800,
        discount   : 0
      },
    
      {
        category   : 'Верхняя одежда',
        title      : 'Куртка лапки',
        description: 'Куртка с дизайнерским принтом, фасон оверсайз. Светится под неоновым светом. Теплый наполнитель до -25\n',
        sizes      : ['xs/s', 's/m', 'm/l', 'm', 'l'],
        color      : 'Мультиколор',
        image      : 'images/catalog/8.jpg',
        slider     : [
          'images/lapki-jacket/1.jpg',
          'images/lapki-jacket/2.jpg',
          'images/lapki-jacket/3.jpg'
        ],
        check      : true,
        sell       : false,
        price      : 3500,
        discount   : 0
      },
      {
        category   : 'Верхняя одежда',
        title      : 'Куртка лапки',
        description: 'Куртка с дизайнерским принтом, фасон оверсайз. Светится под неоновым светом. Теплый наполнитель до -25\n',
        sizes      : ['xs/s', 's/m', 'm/l', 'm', 'l'],
        color      : 'Мультиколор',
        image      : 'images/catalog/9.jpg',
        slider     : [
          'images/haki-jacket/1.jpg',
          'images/haki-jacket/2.jpg',
          'images/haki-jacket/3.jpg'
        ],
        check      : true,
        sell       : false,
        price      : 3500,
        discount   : 0
      },
      {
        category   : 'Верхняя одежда',
        title      : 'Куртка бежевая оверсайз',
        description: 'Куртка с дизайнерским принтом, фасон оверсайз. Светится под неоновым светом. Теплый наполнитель до -25\n',
        sizes      : ['xs/s', 's/m', 'm/l', 'm', 'l'],
        color      : 'Бежевый',
        image      : 'images/catalog/10.jpg',
        slider     : [
          'images/beige-jacket/1.jpg',
          'images/beige-jacket/2.jpg',
          'images/beige-jacket/3.jpg'
        ],
        check      : true,
        sell       : false,
        price      : 2800,
        discount   : 0
      },
      {
        category   : 'Верхняя одежда',
        title      : 'Куртка синий космос',
        description: 'Куртка с дизайнерским принтом, фасон оверсайз. Светится под неоновым светом. Теплый наполнитель до -25\n',
        sizes      : ['xs/s', 's/m', 'm/l', 'm', 'l'],
        color      : 'Синий',
        image      : 'images/catalog/11.jpg',
        slider     : [
          'images/blue-jacket/1.jpg',
          'images/blue-jacket/2.jpg',
          'images/blue-jacket/3.jpg'
        ],
        check      : true,
        sell       : false,
        price      : 3500,
        discount   : 0
      },
      {
        category   : 'Верхняя одежда',
        title      : 'Куртка черная',
        description: 'Куртка с дизайнерским принтом, фасон оверсайз. Светится под неоновым светом. Теплый наполнитель до -25\n',
        sizes      : ['xs/s', 's/m', 'm/l', 'm', 'l'],
        color      : 'Черный',
        image      : 'images/catalog/12.jpg',
        slider     : [
          'images/black-jacket/1.jpg',
          'images/black-jacket/2.jpg',
          'images/black-jacket/3.jpg'
        ],
        check      : true,
        sell       : false,
        price      : 3500,
        discount   : 0
      }
  
    ],
    busked: []
  },
  mutations: {
    addToBasked(state, item){
      state.busked.push(item)
    },
    delBasked(state, index){
      state.busked.splice(index, 1)
    },
    clearBasked(state){
      state.busked = []
    },
  },
  actions: {
  
  },
  modules: {
  }
})
