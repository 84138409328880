<template>
  <div class="button-frame">
    <a href="javascript:void(0)" class="default-btn dark-btn" @click="orderStore">
      <span class="btn-text">Замовити магазин</span>
      <span class="icon icon-light"></span>
    </a>
  </div>
  <div class="frame-modal-wrapper quick-cart" id="quick_cart">
    <div class="frame-modal-body">
      <ul class="frame-modal-list">
        <li>
          <div class="frame-modal-header">
            <div class="name h2" v-if="basked.length">Товары в корзине</div>
            <div class="name h2" v-else>Карзина пуста. <br> Добавьте товар из каталога.</div>

          </div>
        </li>
        <li class="cart-item cart-item-first" v-for="(item,index) in basked" :key="index">
          <div class="item-holder">
            <a class="remove" @click="delBasked(item, index)">
              <span class="icon icon-cross"></span>
            </a>
            <div class="item-info">
              <div class="image bg" :style="`background-image: url(/${item.image});`">
                <div class="size">{{item.size}}</div>
              </div>
              <div class="right-side">
                <div class="name">{{item.title}}</div>
                <div class="options-panel">
                  <div class="quantity d-flex align-items-center">
                    <div class="nt-spinner">
                      <button class=" nt-btn btn_less icons icon-minus" type="button" @click="addItemCount(item, -1)"></button>
                      <input type="text" class="form-control " v-model="item.count">
                      <button class=" nt-btn btn_more icons icon-plus" type="button" @click="addItemCount(item, +1)"></button>
                    </div>
                  </div>
                  <div class="price-box" v-if="item.discount === 0">
                    <div class="price-element">₴ {{item.price * item.count}}</div>
                  </div>
                  <div class="price-box" v-else>
                    <div class="price-element">₴ {{(item.price - item.discount) * item.count}}</div>
                    <div class="price-element price-old">₴ {{item.price * item.count}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>

      </ul>
    </div>
    <div class="frame-modal-bottom" v-if="basked.length">
      <button class="default-btn green-btn">
        <span class="btn-text" @click="makeOrder">Оформить заказ</span>
      </button>
      <div class="link-holder">
        <a href="javascript:void(0)" @click="closeModal" class="back-link"><span class="icon icon-left"></span><span class="text">Продолжить покупки</span></a>
      </div>
    </div>
  </div>
  <div class="frame-modal-wrapper-bg"></div>
  <div class="product-top-panel">
    <router-link :to="{name:'Home'}" class="back-link"><span class="icon icon-left"></span><span class="text">Назад в категорию</span>
    </router-link>
<!--    <div class="quick-cart">-->
<!--      <a href="javascript:void(0);" class="quick_cart_trigger">-->
<!--        <span class="notification">{{baskedCount}}</span>-->
<!--        <span class="icon icon-bag-filled"></span>-->
<!--      </a>-->
<!--    </div>-->
  </div>

  <div class="product-images" v-if="item">
    <div class="tile_label tile_sale" v-if="item.discount">-10%</div>
    <div class="tile_label tile_new" v-if="item.sell">new</div>
    <ul>
      <li v-for="(img, index) in item.slider" :key="index">
        <a :href="`/${img}`" data-fancybox="zoom" class="image bg" :style="`background-image: url(/${img});`"></a>
      </li>
    </ul>
  </div>

  <div class="product-wrapper wrapper" v-if="item">
    <div class="name h2">{{item.title}}</div>
    <div class="product-options">
      <div class="price-box" v-if="item.discount === 0">
        <div class="price-element">₴ {{item.price}}</div>
      </div>
      <div class="price-box" v-else>
        <div class="price-element">₴ {{item.price - item.discount}}</div>
        <div class="price-element price-old">₴ {{item.price}}</div>
      </div>
      <div class="availability">
        В наличии <span class="icon icon-in-stock"></span>
      </div>
    </div>
    <!--sizes-->
    <div class="size-panel d-flex">
      <div class="label-name">Размер:</div>
      <div class="size-checkbox-group">
        <!--size checkbox-->
        <div class="option-radio" v-for="(s, i) in item.sizes" :key="i">
          <input :id="s" type="radio" name="color_select" checked="" v-model="order.size" :value="s">
          <label :for="s">{{s}}</label>
        </div>
        <!--end size checkbox-->

      </div>
    </div>

    <div class="quick-card-sticker " id="quick_card_sticker">
      <div class="quick-card-sticker-holder">
        <div class="quick-cart">
          <a href="javascript:void(0);" class="quick_cart_trigger">
            <span class="notification">{{baskedCount}}</span>
            <span class="icon icon-bag-filled"></span>
          </a>
        </div>
      </div>
    </div>
    <!--end sizes-->
    
    <!--change in the quantity of goods-->
    <div class="quantity d-flex align-items-center">
      <div class="label-name">Количество:</div>
      <div class="nt-spinner">
        <button class=" nt-btn btn_less icons icon-minus" type="button" @click="addCount(-1)"></button>
        <input type="number" class="form-control" v-model="order.count">
        <button class=" nt-btn btn_more icons icon-plus" type="button" @click="addCount(1)"></button>
      </div>
    </div>
    <!--end change in the quantity of goods-->
    <div class="button-holder" >
      <button class="default-btn quick_cart_trigger" @click="buy">
        <span class="btn-text">Купить товар</span>
        <span class="icon icon-bag"></span>
      </button>
    </div>
    <div class="product-info">
      <a class="collapse-link h2" data-toggle="collapse" href="#collapseInfo" role="button" aria-expanded="false"
         aria-controls="collapseInfo">
        О товаре
      </a>
      <div class="collapse" id="collapseInfo">
        <div class="text">
          {{item.description}}
        </div>
      </div>
    </div>

    <!--=======================-->
    <div class="related-products">
      <div class="name h2">Похожие товары</div>
      <ul class="catalog-grid">
        <li class="catalog-item" v-for="(item, i) in samples" :key="i">
          <router-link :to="{name: 'Item', params:{id: item.id}}" class="image bg" :style="`background-image: url(/${item.image});`">
            <div class="tile_label tile_sale" v-if="item.discount">-10%</div>
            <div class="tile_label tile_new" v-if="item.sell">new</div>
          </router-link>
          <div class="item-content">
            <a :href="item.id" class="name">{{item.title}}</a>

            <div class="price-box" v-if="item.discount === 0">
              <div class="price-element">₴ {{item.price}}</div>
            </div>
            <div class="price-box" v-else>
              <div class="price-element">₴ {{item.price - item.discount}}</div>
              <div class="price-element price-old">₴ {{item.price}}</div>
            </div>

          </div>
          <a :href="item.id" class="icon-btn">
            <span class="icon icon-right"></span>
          </a>
        </li>

      </ul>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    name      : 'Item',
    props     : ['id'],
    components: {},
    data() {
      return {
        order: {size: '', count: 1}
      }
    },
    watch     : {
      id() {
        window.scrollTo(0, 0);
        this.order.size = this.item.sizes[0];
      }
    },
    computed  : {
      item() {
        if (this.id) {
          return this.$store.state.items[this.id];
        }
        return undefined
      },
      samples() {
        return this.$store.state.items.map((i, index) => {
          i.id = index;
          return i
        }).filter(i => i.category === this.item.category)
      },
      basked() {
        return this.$store.state.busked
      },
      baskedCount() {
        return this.$store.state.busked.length
      }

    },
    methods   : {
      orderStore(){
        if (window.Telegram) {
          axios.post('/api/lead', window.Telegram ? window.Telegram.WebApp.initData : '', {
            headers: {
              initData: window.Telegram ? window.Telegram.WebApp.initData : ''
            }
          }).then(() => {
            document.location.replace('https://t.me/UXLoveBot');
          });
        } else {
          document.location.replace('https://t.me/UXLoveBot?start=invoice_from_demo');
        }

      },
      addCount(i) {
        if (i + this.order.count) {
          this.order.count += i
        }
      },
      addItemCount(item, i) {
        if (i + item.count) {
          item.count += i
        }
      },
      closeModal(){
        window.$('.frame-modal-wrapper-bg').removeClass('is-visible');
        window.$('body').removeClass('no-scroll');

        window.$('.frame-modal-list .first-level-item .level-sub').removeClass('open-level');
        window.$('#sort_filter,#sorting,#category_panel,#quick_cart').removeClass('opened');
      },
      makeOrder() {
        axios.post('/api/order', this.basked, {
          headers: {
            initData: window.Telegram ? window.Telegram.WebApp.initData : ''
          }
        }).then(() => {
          this.$store.commit('clearBasked');

          if (window.Telegram) {
            window.Telegram.WebApp.close()
          }

          window.$('.frame-modal-wrapper-bg').removeClass('is-visible');
          window.$('body').removeClass('no-scroll');

          window.$('.frame-modal-list .first-level-item .level-sub').removeClass('open-level');
          window.$('#sort_filter,#sorting,#category_panel,#quick_cart').removeClass('opened');

        });
      },
      buy() {
        let idx = this.basked.findIndex(i => i.size === this.order.size && i.id === this.item.id);
        if (idx > -1) {
          this.basked[idx].count += this.order.count;
        } else {
          this.$store.commit('addToBasked', Object.assign({}, this.order, this.item));
        }
        window.$('body').addClass('no-scroll');
        window.$('#quick_cart').addClass('opened');
        window.$('.frame-modal-wrapper-bg').addClass('is-visible');
      },
      delBasked(item, index) {
        this.$store.commit('delBasked', index)
      }
    },
    mounted() {
      window.scrollTo(0, 0);
      //  product-images
      window.$('.product-images a').fancybox();
      this.order.size = this.item.sizes[0];
      window.$('.quick_cart_trigger').on('click', function () {
        window.$('body').addClass('no-scroll');
        window.$('#quick_cart').addClass('opened');
        window.$('.frame-modal-wrapper-bg').addClass('is-visible');
        return false;
      });

      window.$('.frame-modal-wrapper-bg').on('click', function () {
        window.$(this).removeClass('is-visible');
        window.$('body').removeClass('no-scroll');

        window.$('.frame-modal-list .first-level-item .level-sub').removeClass('open-level');
        window.$('#sort_filter,#sorting,#category_panel,#quick_cart').removeClass('opened');
        return false;
      });
    }
  }
</script>