<template>

  <div class="button-frame">
    <a href="javascript:void(0)" class="default-btn dark-btn" @click="orderStore">
      <span class="btn-text">Замовити магазин</span>
      <span class="icon icon-light"></span>
    </a>
  </div>
  <div class="option-panel">
    <ul>
      <li>
        <a href="javascript:void(0);" class="option-btn filter_trigger">
          <span class="btn-text">Фильтр</span>
          <span class="icon icon-settings"></span>
        </a>
      </li>
      <li>
        <a href="javascript:void(0);" class="option-btn sort_trigger">
          <span class="btn-text">Сортировка</span>
          <span class="icon icon-sort"></span>
        </a>
      </li>
      <li>
        <a href="javascript:void(0);" class="option-btn category_trigger">
          <span class="btn-text">Категории</span>
          <span class="icon icon-down"></span>
        </a>
      </li>
    </ul>
  </div>

  <div class="frame-modal-wrapper " id="sort_filter">
    <div class="frame-modal-body">

      <ul class="frame-modal-list">
        <li>
          <div class="frame-modal-header">
            <div class="name h2">Сортировка и фильтр</div>
            <a href="javascript:void(0);" class="reset" style="display: none;"><span class="icon icon-refresh"></span><span class="text">Сбросить</span></a>
          </div>
        </li>
        <li class="first-level-item sort-level-item">
          <a href="javascript:void(0);" class="first-level-link">
            <span class="link-value"><span class="icon icon-sort"></span>Сортировка</span>
            <span class="link-name"><span class="text">По умолчанию</span><span class="icon icon-right"></span></span>
          </a>
          <div class="level-sub ">
            <div class="top-sub">
              <a href="javascript:void(0);" class="back-trigger"><span class="icon icon-left"></span>Назад</a>
              <span class="top-sub-name">Сортировка</span>
            </div>
            <ul class="level-sub-list">
              <li>
                <div class="filter-radio">
                  <input id="default_sort" type="radio" name="sort_select" checked="" v-model="sorting" value="По умолчанию">
                  <label for="default_sort">По умолчанию</label>
                </div>
              </li>
              <li>
                <div class="filter-radio">
                  <input id="price_up" type="radio" name="sort_select" v-model="sorting" value="От дешевых к дорогим">
                  <label for="price_up">От дешевых к дорогим</label>
                </div>
              </li>
              <li>
                <div class="filter-radio">
                  <input id="price_down" type="radio" name="sort_select" v-model="sorting" value="От дорогих к дешевым">
                  <label for="price_down">От дорогих к дешевым</label>
                </div>
              </li>
              <li>
                <div class="filter-radio">
                  <input id="popular_sort" type="radio" name="sort_select" v-model="sorting" value="По популярности">
                  <label for="popular_sort">По популярности</label>
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li class="first-level-item">
          <a href="javascript:void(0);" class="first-level-link">
            <span class="link-value"><span class="icon icon-dollar"></span>Цена</span>
            <span class="link-name"><span class="text">₴ {{filter.price_low}} - ₴ {{filter.price_high}}</span><span
                class="icon icon-right"></span></span>
          </a>
          <div class="level-sub">
            <div class="top-sub">
              <a href="javascript:void(0);" class="back-trigger"><span class="icon icon-left"></span>Назад</a>
              <span class="top-sub-name">Цена</span>
            </div>
            <ul class="level-sub-list">
              <li class="price-selector">
                <div class="ranger-holder">
                  <div class="form-group d-flex justify-content-between">
                    <input v-model.number="filter.price_low" type="number" min="0" max="10000" step="100" id="input-number1"
                           class="form-control custom-input">
                    <input v-model.number="filter.price_high" type="number" min="0" max="10000" step="100" id="input-number2"
                           class="form-control custom-input">
                  </div>
                  <div id="slider-price"></div>


                  <div class="num-line-ranger d-flex justify-content-between">
                    <span>₴ 1000</span>
                    <span>₴ 5000</span>
                    <span>₴ 10 000</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li class="first-level-item">
          <a href="javascript:void(0);" class="first-level-link">
            <span class="link-value"><span class="icon icon-color"></span>Цвет</span>
            <span class="link-name"><span class="text">{{colorsName}}</span><span class="icon icon-right"></span></span>
          </a>
          <div class="level-sub ">
            <div class="top-sub">
              <a href="javascript:void(0);" class="back-trigger"><span class="icon icon-left"></span>Назад</a>
              <span class="top-sub-name">Цвет</span>
            </div>
            <ul class="level-sub-list">
              <div class="filter-checkbox">
                <input id="all_color" type="checkbox" v-model="all_colors" value="1">
                <label for="all_color">Все</label>
              </div>
              <div class="filter-checkbox">
                <input id="black_color" type="checkbox" v-model="filter.colors" value="Черный">
                <label for="black_color">Черный</label>
              </div>
              <div class="filter-checkbox">
                <input id="violet_color" type="checkbox" checked="" v-model="filter.colors" value="Фиолетовый">
                <label for="violet_color">Фиолетовый</label>
              </div>
              <div class="filter-checkbox">
                <input id="beige_color" type="checkbox" v-model="filter.colors" value="Бежевый">
                <label for="beige_color">Бежевый</label>
              </div>
              <div class="filter-checkbox">
                <input id="multi_color" type="checkbox" v-model="filter.colors" value="Мультиколор">
                <label for="multi_color">Мультиколор</label>
              </div>
              <div class="filter-checkbox">
                <input id="green_color" type="checkbox" checked="" v-model="filter.colors" value="Зеленый">
                <label for="green_color">Зеленый</label>
              </div>
              <div class="filter-checkbox">
                <input id="white_color" type="checkbox" v-model="filter.colors" value="Белый">
                <label for="white_color">Белый</label>
              </div>
<!--              <div class="filter-checkbox">-->
<!--                <input id="red_color" type="checkbox" v-model="filter.colors" value="Красный">-->
<!--                <label for="red_color">Красный</label>-->
<!--              </div>-->
              <div class="filter-checkbox">
                <input id="blue_color" type="checkbox" v-model="filter.colors" value="Синий">
                <label for="blue_color">Синий</label>
              </div>
            </ul>
          </div>
        </li>
        <li class="first-level-item">
          <a href="javascript:void(0);" class="first-level-link">
            <span class="link-value"><span class="icon icon-size"></span>Размер</span>
            <span class="link-name"><span class="text">{{sizesName}}</span><span class="icon icon-right"></span></span>
          </a>
          <div class="level-sub">
            <div class="top-sub">
              <a href="javascript:void(0);" class="back-trigger"><span class="icon icon-left"></span>Назад</a>
              <span class="top-sub-name">Размер</span>
            </div>
            <ul class="level-sub-list">
              <div class="filter-checkbox">
                <input id="all_size" type="checkbox" checked="" v-model="all_sizes" value="1">
                <label for="all_size">Все</label>
              </div>
              <div class="filter-checkbox">
                <input id="onesize" type="checkbox" v-model="filter.sizes" value="onesize">
                <label for="onesize">onesize</label>
              </div>
              <div class="filter-checkbox">
                <input id="xs_s" type="checkbox" v-model="filter.sizes" value="xs/s">
                <label for="xs_s">xs/s</label>
              </div>
              <div class="filter-checkbox">
                <input id="s_m" type="checkbox" v-model="filter.sizes" value="s/m">
                <label for="s_m">s/m</label>
              </div>
              <div class="filter-checkbox">
                <input id="m_l" type="checkbox" v-model="filter.sizes" value="m/l">
                <label for="m_l">m/l</label>
              </div>
              <div class="filter-checkbox">
                <input id="m" type="checkbox" v-model="filter.sizes" value="m">
                <label for="m">m</label>
              </div>
              <div class="filter-checkbox">
                <input id="l" type="checkbox" v-model="filter.sizes" value="l">
                <label for="l">l</label>
              </div>

            </ul>
          </div>
        </li>
        <li class="first-level-item">
          <a href="javascript:void(0);" class="first-level-link">
            <span class="link-value"><span class="icon icon-shirt"></span>Тип</span>
            <span class="link-name"><span class="text">{{filter.category}}</span><span class="icon icon-right"></span></span>
          </a>
          <div class="level-sub">
            <div class="top-sub">
              <a href="javascript:void(0);" class="back-trigger"><span class="icon icon-left"></span>Назад</a>
              <span class="top-sub-name">Размер</span>
            </div>
            <ul class="level-sub-list">
              <li>
                <div class="filter-radio">
                  <input id="all_type" type="radio" name="sort_type" checked="" v-model="filter.category" value="Все">
                  <label for="all_type">Все</label>
                </div>
              </li>
              <li>
                <div class="filter-radio">
                  <input id="outerwear_type" type="radio" name="sort_type" v-model="filter.category" value="Верхняя одежда">
                  <label for="outerwear_type">Верхняя одежда</label>
                </div>
              </li>
              <li>
                <div class="filter-radio">
                  <input id="costume_type" type="radio" name="sort_type" v-model="filter.category" value="Костюмы">
                  <label for="costume_type">Костюмы</label>
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div class="first-level-link">
            <div class="link-value"><span class="icon icon-tag"></span>Распродажа</div>
            <div class="toggle-holder">
              <div class="toggler r">
                <input type="checkbox" class="toggle-check" v-model="filter.sale">
                <div class="knobs"></div>
                <div class="layer"></div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="first-level-link">
            <div class="link-value"><span class="icon icon-check-circle"></span>В наличии</div>
            <div class="toggle-holder">
              <div class="toggler r">
                <input type="checkbox" class="toggle-check" v-model="filter.check">
                <div class="knobs"></div>
                <div class="layer"></div>
              </div>
            </div>
          </div>
        </li>
      </ul>

    </div>
    <div class="frame-modal-bottom">
      <button class="default-btn green-btn">
        <span class="btn-text" @click="makeFilter">Применить фильтры</span>
      </button>
    </div>

  </div>
  <div class="frame-modal-wrapper default-modal-wrapper" id="sorting">
    <div class="frame-modal-body">
      <ul class="frame-modal-list">
        <li>
          <div class="frame-modal-header">
            <div class="name h2">Сортировaть</div>
          </div>
        </li>
        <li class="first-level-item">
          <div class="level-sub ">
            <ul class="level-sub-list">
              <li>
                <div class="filter-radio">
                  <input id="default_sort_" type="radio" name="sort_select_" checked="" v-model="sorting" value="По умолчанию">
                  <label for="default_sort_">По умолчанию</label>
                </div>
              </li>
              <li>
                <div class="filter-radio">
                  <input id="price_up_" type="radio" name="sort_select_" v-model="sorting" value="От дешевых к дорогим">
                  <label for="price_up_">От дешевых к дорогим</label>
                </div>
              </li>
              <li>
                <div class="filter-radio">
                  <input id="price_down_" type="radio" name="sort_select_" v-model="sorting" value="От дорогих к дешевым">
                  <label for="price_down_">От дорогих к дешевым</label>
                </div>
              </li>
              <li>
                <div class="filter-radio">
                  <input id="popular_sort_" type="radio" name="sort_select_" v-model="sorting" value="По популярности">
                  <label for="popular_sort_">По популярности</label>
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <div class="frame-modal-bottom">
      <button class="default-btn green-btn">
        <span class="btn-text" @click="makeFilter">Применить</span>
      </button>
    </div>
  </div>
  <div class="frame-modal-wrapper default-modal-wrapper" id="category_panel">
    <div class="frame-modal-body">
      <ul class="frame-modal-list">
        <li>
          <div class="frame-modal-header">
            <div class="name h2">Выбрать категорию</div>
          </div>
        </li>
        <li class="first-level-item">
          <div class="level-sub ">
            <ul class="level-sub-list">
              <li>
                <div class="filter-radio">
                  <input id="all_type_" type="radio" name="sort_type_" checked="" v-model="filter.category" value="Все">
                  <label for="all_type_">Все</label>
                </div>
              </li>
              <li>
                <div class="filter-radio">
                  <input id="outerwear_type_" type="radio" name="sort_type_" v-model="filter.category" value="Верхняя одежда">
                  <label for="outerwear_type_">Верхняя одежда</label>
                </div>
              </li>
              <li>
                <div class="filter-radio">
                  <input id="costume_type_" type="radio" name="sort_type_" v-model="filter.category" value="Костюмы">
                  <label for="costume_type_">Костюмы</label>
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <div class="frame-modal-bottom">
      <button class="default-btn green-btn">
        <span class="btn-text" @click="makeFilter">Применить</span>
      </button>
    </div>
  </div>

  <div class="frame-modal-wrapper-bg"></div>
  <div class="fixed-filter-btn">
    <a href="javascript:void(0);" class="option-btn sort_filter_trigger">
      <span class="btn-text">Сортировка и фильтр</span>
      <span class="icon icon-sort"></span>
    </a>
  </div>


  <div class="catalog-wrapper wrapper">
    <div class="large-catalog-item">
      <router-link :to="{name: 'Item', params:{id: first.id}}" class="image bg" :style="`background-image: url(${first.image});`"
                   v-if="first">
					<span class="text">
						<span class="name h2">{{first.title}}</span>
						<span class="price-element">₴ {{first.price}}</span>
						<span class="item-link">Смотреть <span class="icon icon-right"></span></span>
					</span>
      </router-link>
    </div>

    <ul class="catalog-grid">
      <li class="catalog-item" v-for="(item, i) in catalog" :key="i">
        <router-link :to="{name: 'Item', params:{id: item.id}}" class="image bg" :style="`background-image: url(/${item.image});`">
          <div class="tile_label tile_sale" v-if="item.discount">-10%</div>
          <div class="tile_label tile_new" v-if="item.sell">new</div>
        </router-link>
        <div class="item-content">
          <router-link :to="{name: 'Item', params:{id: item.id}}" class="name">
            {{item.title}}
          </router-link>
          <div class="price-box" v-if="item.discount === 0">
            <div class="price-element">₴ {{item.price}}</div>
          </div>
          <div class="price-box" v-else>
            <div class="price-element">₴ {{item.price - item.discount}}</div>
            <div class="price-element price-old">₴ {{item.price}}</div>
          </div>

        </div>
        <router-link :to="{name: 'Item', params:{id: item.id}}" class="icon-btn">
          <span class="icon icon-right"></span>
        </router-link>
<!--        <a :href="item.id" class="icon-btn">-->
<!--          <span class="icon icon-right"></span>-->
<!--        </a>-->
      </li>
    </ul>
  </div>

  <Teleport to="body">
    <div class="frame-modal-wrapper quick-cart" id="quick_cart">
      <div class="frame-modal-body">
        <ul class="frame-modal-list">
          <li>
            <div class="frame-modal-header">
              <div class="name h2" v-if="basked.length">Товары в корзине</div>
              <div class="name h2" v-else>Карзина пуста. <br> Добавьте товар из каталога.</div>

            </div>
          </li>
          <li class="cart-item cart-item-first" v-for="(item,index) in basked" :key="index">
            <div class="item-holder">
              <a class="remove" @click="delBasked(item, index)">
                <span class="icon icon-cross"></span>
              </a>
              <div class="item-info">
                <div class="image bg" :style="`background-image: url(/${item.image});`">
                  <div class="size">{{item.size}}</div>
                </div>
                <div class="right-side">
                  <div class="name">{{item.title}}</div>
                  <div class="options-panel">
                    <div class="quantity d-flex align-items-center">
                      <div class="nt-spinner">
                        <button class=" nt-btn btn_less icons icon-minus" type="button" @click="addItemCount(item, -1)"></button>
                        <input type="text" class="form-control " v-model="item.count">
                        <button class=" nt-btn btn_more icons icon-plus" type="button" @click="addItemCount(item, +1)"></button>
                      </div>
                    </div>
                    <div class="price-box" v-if="item.discount === 0">
                      <div class="price-element">₴ {{item.price * item.count}}</div>
                    </div>
                    <div class="price-box" v-else>
                      <div class="price-element">₴ {{(item.price - item.discount) * item.count}}</div>
                      <div class="price-element price-old">₴ {{item.price * item.count}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>

        </ul>
      </div>
      <div class="frame-modal-bottom" v-if="basked.length">
        <button class="default-btn green-btn">
          <span class="btn-text" @click="makeOrder">Оформить заказ</span>
        </button>
        <div class="link-holder">
          <a href="javascript:void(0)" @click="closeModal" class="back-link"><span class="icon icon-left"></span><span class="text">Продолжить покупки</span></a>
        </div>
      </div>
    </div>

    <div class="quick-card-sticker " id="quick_card_sticker">
      <div class="quick-card-sticker-holder">
        <div class="quick-cart">
          <a href="javascript:void(0);" class="quick_cart_trigger">
            <span class="notification">{{baskedCount}}</span>
            <span class="icon icon-bag-filled"></span>
          </a>
        </div>
      </div>
    </div>

  </Teleport>

</template>

<script>
  // @ is an alias to /src
  // import HelloWorld from '@/components/HelloWorld.vue'
  // import $ from 'jquery';
  import axios from 'axios';

  export default {
    name      : 'Home',
    components: {},
    data() {
      return {
        filter : {
          price_low : 1000,
          price_high: 8000,
          colors    : [],
          sizes     : [],
          category  : 'Все',
          sale      : false,
          check     : false
        },
        sorting: ''

      }
    },
    computed  : {
      basked() {
        return this.$store.state.busked
      },
      baskedCount() {
        return this.$store.state.busked.length
      },

      all_colors: {
        get() {
          return this.filter.colors.length === 0
        },
        set() {
          this.filter.colors = []
        }
      },
      all_sizes : {
        get() {
          return this.filter.sizes.length === 0
        },
        set() {
          this.filter.sizes = []
        }
      },
      colorsName() {
        return this.filter.colors.length ? this.filter.colors.join(', ') : 'Все'
      },
      sizesName() {
        return this.filter.sizes.length ? this.filter.sizes.join(', ') : 'Все'
      },

      filtered() {
        return this.$store.state.items.map((i, index) => {
          i.id = index;
          return i
        }).filter(i => {
          if (this.filter.category !== 'Все' && i.category !== this.filter.category) {
            return false
          }
          if (this.filter.colors.length && this.filter.colors.indexOf(i.color) === -1) {
            return false
          }
          if (this.filter.price_low > i.price || i.price > this.filter.price_high) {
            return false
          }
          if (this.filter.sale) {
            if (!(i.sell || i.discount > 0)) {
              return false
            }
          }

          return true
        }).sort((a, b) => {
          switch (this.sorting) {
          case 'От дорогих к дешевым':
            return b.price - a.price;
          case 'От дешевых к дорогим':
            return a.price - b.price;
          default:
            return 0
          }
        })
      },
      first() {
        return this.filtered[0]
      },
      catalog() {
        return this.filtered.map(i => i).splice(1)
      }
    },
    methods   : {
      orderStore(){
        if (window.Telegram) {
          axios.post('/api/lead', window.Telegram ? window.Telegram.WebApp.initData : '', {
            headers: {
              initData: window.Telegram ? window.Telegram.WebApp.initData : ''
            }
          }).then(() => {
            document.location.replace('https://t.me/UXLoveBot');
          });
        } else {
          document.location.replace('https://t.me/UXLoveBot?start=invoice_from_demo');
        }

      },
      closeModal(){
        window.$('.frame-modal-wrapper-bg').removeClass('is-visible');
        window.$('body').removeClass('no-scroll');

        window.$('.frame-modal-list .first-level-item .level-sub').removeClass('open-level');
        window.$('#sort_filter,#sorting,#category_panel,#quick_cart').removeClass('opened');
      },
      makeOrder() {
        axios.post('/api/order', this.basked, {
          headers: {
            initData: window.Telegram ? window.Telegram.WebApp.initData : ''
          }
        }).then(() => {
          this.$store.commit('clearBasked');

          if (window.Telegram) {
            window.Telegram.WebApp.close()
          }

          window.$('.frame-modal-wrapper-bg').removeClass('is-visible');
          window.$('body').removeClass('no-scroll');

          window.$('.frame-modal-list .first-level-item .level-sub').removeClass('open-level');
          window.$('#sort_filter,#sorting,#category_panel,#quick_cart').removeClass('opened');

        });
      },
      makeFilter() {
        window.$('.frame-modal-wrapper-bg').removeClass('is-visible');
        window.$('body').removeClass('no-scroll');

        window.$('.frame-modal-list .first-level-item .level-sub').removeClass('open-level');
        window.$('#sort_filter,#sorting,#category_panel,#quick_cart').removeClass('opened');

      }
    },
    beforeUnmount(){
      var html5Slider = document.getElementById('slider-price');

      html5Slider.noUiSlider.destroy()
    },
    mounted() {
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.expand();
      // document.getElementById('subtitle').innerText = navigator.userAgent;
      if (navigator.userAgent.indexOf("iPhone") > -1){
        window.Telegram.WebApp.onEvent('viewportChanged', window.Telegram.WebApp.expand)
      }

      window.$('.sort_filter_trigger').on('click', function () {
        window.$('body').addClass('no-scroll');
        window.$('#sort_filter').addClass('opened');
        window.$('.frame-modal-wrapper-bg').addClass('is-visible');
        window.$('.sort-level-item').removeClass('hidden');
        return false;
      });
      window.$('.category_trigger').on('click', function () {
        window.$('body').addClass('no-scroll');
        window.$('#category_panel').addClass('opened');
        window.$('.frame-modal-wrapper-bg').addClass('is-visible');
        return false;
      });
      window.$('.filter_trigger').on('click', function () {
        window.$('body').addClass('no-scroll');
        window.$('#sort_filter').addClass('opened');
        window.$('.sort-level-item').addClass('hidden');
        window.$('.frame-modal-wrapper-bg').addClass('is-visible');
        return false;
      });
      window.$('.sort_trigger').on('click', function () {
        window.$('body').addClass('no-scroll');
        window.$('#sorting').addClass('opened');
        window.$('.frame-modal-wrapper-bg').addClass('is-visible');
        return false;
      });
      window.$('.quick_cart_trigger').on('click', function () {
        window.$('body').addClass('no-scroll');
        window.$('#quick_cart').addClass('opened');
        window.$('.frame-modal-wrapper-bg').addClass('is-visible');
        return false;
      });

      window.$('.frame-modal-wrapper-bg').on('click', function () {
        window.$(this).removeClass('is-visible');
        window.$('body').removeClass('no-scroll');

        window.$('.frame-modal-list .first-level-item .level-sub').removeClass('open-level');
        window.$('#sort_filter,#sorting,#category_panel,#quick_cart').removeClass('opened');
        return false;
      });

      window.$('.frame-modal-list li.first-level-item ').on('click', function () {
        window.$(this).find('.level-sub').addClass('open-level');

      });
      window.$('.frame-modal-list .top-sub a.back-trigger').on('click', function () {
        window.$('.frame-modal-list .first-level-item .level-sub').removeClass('open-level');
        return false;
      });

      window.$(function () {
        if (window.$(window).scrollTop() >= 100) {
          window.$('.fixed-filter-btn').addClass('stickybottom');
        }
        window.$(window).scroll(function () {
          if (window.$(this).scrollTop() >= 100) {
            window.$('.fixed-filter-btn').addClass('stickybottom');
          } else {
            window.$('.fixed-filter-btn').removeClass('stickybottom');
          }
        });

      });

      window.$(function () {
        if (window.$(window).scrollTop() >= 100) {
          window.$('#quick_card_sticker').addClass('stickytop');
        }
        window.$(window).scroll(function () {
          if (window.$(this).scrollTop() >= 100) {
            window.$('#quick_card_sticker').addClass('stickytop');
          } else {
            window.$('#quick_card_sticker').removeClass('stickytop');
          }
        });

      });

      window.onload = function () {
        document.documentElement.classList.add('show-content');
      };

      var html5Slider = document.getElementById('slider-price');

      window.noUiSlider.create(html5Slider, {
        start  : [1000, 8000],
        connect: true,
        step   : 100,
        range  : {
          'min': [0],
          'max': 10000
        }
      });

      var inputNumber1 = document.getElementById('input-number1');
      var inputNumber2 = document.getElementById('input-number2');
      let filter       = this.filter;

      html5Slider.noUiSlider.on('update', function (values, handle) {
        if (handle) {
          filter.price_high = parseInt(values[handle], 10)
        } else {
          filter.price_low = parseInt(values[handle], 10)
        }
      });

      inputNumber1.addEventListener('change', function () {
        html5Slider.noUiSlider.set([null, this.value]);
      });
      inputNumber2.addEventListener('change', function () {
        html5Slider.noUiSlider.set([null, this.value]);
      });
    }

  }
</script>
