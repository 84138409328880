import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Item from '../views/Item.vue'

const routes = [
  {
    path     : '/',
    name     : 'Home',
    component: Home
  },
  {
    path     : '/item/:id',
    name     : 'Item',
    props    : true,
    component: Item
  }
];

const router = createRouter({
                              history: createWebHistory(process.env.BASE_URL),
                              routes,
                              scrollBehavior: (to, from, savedPosition) => {
                                if (savedPosition) {
                                  return savedPosition;
                                } else if (to.hash) {
                                  return {
                                    selector: to.hash
                                  };
                                } else {
                                  return { x: 0, y: 0 };
                                }
                              }
                            });

let last_path = localStorage.getItem('last_path');

function initRecord() {
  router.beforeEach((to) => {
    // console.log(to);
    localStorage.setItem('last_path', to.path);
    return true
  });
  
  let lastTs = 0;
  window.addEventListener('scroll', (event) => {
    if (lastTs + 100 < event.timeStamp) {

      lastTs = event.timeStamp;
      localStorage.setItem('save_y', window.pageYOffset);
    }
  });
}

router.isReady().then(() => {
  if (last_path && window.location.pathname !== last_path) {
    router.push(last_path);
  }
  try {
    let lastSaveY = parseInt(localStorage.getItem('save_y'), 10);
    setTimeout(() => {window.scrollTo(0, lastSaveY)}, 100)
  } catch (e) {
    console.log(e)
  }
  initRecord()
  
});

export default router
